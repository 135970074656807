@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,300&display=swap');

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header h1 {
  position: relative;
  left: 40%;  /* Center the element */
  margin: 0;
  padding: 0;
  font-size: 2.5em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;  /* lighter than normal */
}

.header {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background: linear-gradient(to bottom, #e63946, #e34a33);
  color: white;
  width: 100%;
  padding: 20px 0;
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header nav {
  margin-right: 20px;
}

.header nav a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-family: 'Roboto', sans-serif;
  font-style: italic;
}



.football-icon {
  font-size: 3em;  /* Adjust size as needed */
  position: absolute;
    padding: 20px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
}

.content {
  display: flex;
  flex-direction: row-reverse;  /* Reverse the order of flex items */
  width: 100%;
}

.side-panel {
  position: fixed;  /* Fixed positioning */
  left: 0;
  top: 60px;  /* Adjust this value based on the actual height of your header */
  height: calc(100% - 60px);/* Stick it to the left */
  height: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  z-index: 100;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.game-list {
  width: 80%;  /* You can set this to whatever you prefer */
  margin: 20px auto;  /* Vertical margin and auto horizontal margin */
}

.record-section {
  background: linear-gradient(to bottom, #e63946, #e34a33);
  color: white;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  font-size: 1.2em;
  font-style: italic;
}

h2 {
  text-align: center; /* Center align the text */
  margin: 0 auto; /* Center the block horizontally */
}

.game-card {
  width: 600px;  /* Increase width */
  min-height: 50px;  /* Reduce height */
  padding: 10px;  /* Adjust padding */
  margin: 15px auto;  /* Adds margin to the top and bottom, and auto-aligns left and right margins */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.favorite-line {
  font-weight: bold;
  font-size: 1.2em;
  color: blue
}

.favorite-line.true {
  color: green;
}

.favorite-line.false {
  color: red;
}

.button-gradient {
  background: linear-gradient(to bottom, #ff5f6d, #ffc371);
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
  cursor: pointer;
}
